<template>
  <div class="basicParamsPage">
    <Listcontainer :searchText.sync="searchText" :placeholder="`参数组名称/参数组代码`" :pagination="pagination" @add="add" @multipleDel="multipleDel" @fetch="loadData">
      <template v-slot:grid><Grid :Gridtype="Gridtype" @intoGrid="view" :GridData="data" /></template>
      <template v-slot:defalut> defalut-slot </template>
      <template v-slot:table>
        <Basetable :multi="false" :data="data" :columns="columns" :operations="operations" />
      </template>
    </Listcontainer>
  </div>
</template>
<script>
import Basetable from '@/components/table/base-table/base-table';
import Listcontainer from '@/components/list-container/list-container';
import Grid from '@/components/grid/index.vue';

export default {
  components: {
    Basetable,
    Listcontainer,
    Grid
  },
  data() {
    return {
      Gridtype: 2,
      searchText: '',
      data: [],
      columns: [
        {
          prop: 'name',
          label: '参数名称',
          href: true,
          handle: this.view
        },
        {
          prop: 'code',
          label: '参数代码'
        },
        {
          prop: 'nestNumber',
          label: '被引用数量',
          align: 'center'
        }
      ],
      pagination: {
        total: 100,
        pageSize: 20,
        currentPage: 1
      },
      operations: {
        width: 200,
        handles: []
      }
    };
  },
  mounted() {
    this.loadData();
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  methods: {
    async loadData(param = {}) {
      this.Gridtype = 2;
      const { currentPage, pageSize } = this.pagination;
      const data = {
        currentPage: param.currentPage || currentPage,
        currentPageSize: param.currentPageSize || pageSize,
        keyword: this.searchText,
        groupType: 0
      };
      const res = await this.$apis.basicParamsGroup.list(data);
      this.data = res.items;
      this.pagination = {
        total: res.total,
        pageSize: res.currentPageSize,
        currentPage: res.currentPage
      };
    },
    async view(item) {
      this.$router.push({ path: '/virtualParamGroup/info', query: { data: item } });
    },
    async exportgroup(item) {
      const data = {
        groupId: item.groupId
      };
      const res = await this.$apis.basicParamsGroup.exportValidParam(data);
      if (res) {
        this.$apis.basicParamsGroup.ExportParam(data);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.basicParamsPage {
  height: 100%;
}
/deep/ .header > .addOrdel {
  display: none;
}
</style>
